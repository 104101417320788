import React, { useEffect, useState } from 'react'
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { db } from '../../../firebase'
import './styles.scss'
import { Button, Card, Select, Form, Input, Modal, DatePicker, Spin, Typography, Tabs, Tag, Popconfirm, Upload, message } from 'antd'
import { PlusOutlined, DeleteOutlined, EditOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const Training = ({ user }) => {
    const [trainingModules, setTrainingModules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [currentModule, setCurrentModule] = useState(null);
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        fetchTrainingModules();
    }, []);

    const fetchTrainingModules = async () => {
        setLoading(true);
        try {
            const querySnapshot = await getDocs(collection(db, 'trainingModules'));
            const modules = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setTrainingModules(modules);
        } catch (error) {
            console.error('Error fetching training modules:', error);
            messageApi.error('Failed to load training modules');
        }
        setLoading(false);
    };

    const handleAddModule = async (values) => {
        try {
            const newModule = {
                title: values.title,
                description: values.description || '',
                videoSinhala: values.videoSinhala || '',
                videoTamil: values.videoTamil || '',
                videoEnglish: values.videoEnglish || '',
                materialSinhala: values.materialSinhala || '',
                materialTamil: values.materialTamil || '',
                materialEnglish: values.materialEnglish || '',
                sessionDate: values.sessionDate ? values.sessionDate.format('YYYY-MM-DD') : '',
                createdAt: new Date().toISOString()
            };

            await addDoc(collection(db, 'trainingModules'), newModule);
            messageApi.success('Training module added successfully');
            setAddModalVisible(false);
            form.resetFields();
            fetchTrainingModules();
        } catch (error) {
            console.error('Error adding training module:', error);
            messageApi.error('Failed to add training module');
        }
    };

    const handleEditModule = async (values) => {
        try {
            const updatedModule = {
                title: values.title,
                description: values.description || '',
                videoSinhala: values.videoSinhala || '',
                videoTamil: values.videoTamil || '',
                videoEnglish: values.videoEnglish || '',
                materialSinhala: values.materialSinhala || '',
                materialTamil: values.materialTamil || '',
                materialEnglish: values.materialEnglish || '',
                sessionDate: values.sessionDate ? values.sessionDate.format('YYYY-MM-DD') : '',
                updatedAt: new Date().toISOString()
            };

            await updateDoc(doc(db, 'trainingModules', currentModule.id), updatedModule);
            messageApi.success('Training module updated successfully');
            setEditModalVisible(false);
            editForm.resetFields();
            fetchTrainingModules();
        } catch (error) {
            console.error('Error updating training module:', error);
            messageApi.error('Failed to update training module');
        }
    };

    const handleDeleteModule = async (id) => {
        try {
            await deleteDoc(doc(db, 'trainingModules', id));
            messageApi.success('Training module deleted successfully');
            fetchTrainingModules();
        } catch (error) {
            console.error('Error deleting training module:', error);
            messageApi.error('Failed to delete training module');
        }
    };

    const showEditModal = (module) => {
        setCurrentModule(module);
        editForm.setFieldsValue({
            ...module,
            sessionDate: module.sessionDate ? dayjs(module.sessionDate) : null
        });
        setEditModalVisible(true);
    };

    const renderModuleCard = (module) => {
        return (
            <Card 
                key={module.id} 
                className="module-card"
                title={module.title}
                extra={
                    user?.role === 'super-admin' && (
                        <div className="card-actions">
                            <Button 
                                icon={<EditOutlined />} 
                                type="text" 
                                onClick={() => showEditModal(module)}
                            />
                            <Popconfirm
                                title="Delete this module?"
                                description="This action cannot be undone."
                                onConfirm={() => handleDeleteModule(module.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button 
                                    icon={<DeleteOutlined />} 
                                    type="text" 
                                    danger 
                                />
                            </Popconfirm>
                        </div>
                    )
                }
            >
                {module.sessionDate && (
                    <div className="module-date">
                        <Tag color="blue">Session Date: {module.sessionDate}</Tag>
                    </div>
                )}
                
                {module.description && (
                    <Paragraph className="module-description">
                        {module.description}
                    </Paragraph>
                )}
                
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Sinhala" key="1">
                        <div className="tab-content">
                            {module.videoSinhala && (
                                <div className="video-container">
                                    <iframe 
                                        src={module.videoSinhala} 
                                        title={`${module.title} - Sinhala`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            )}
                            
                            {module.materialSinhala && (
                                <div className="material-link">
                                    <a href={module.materialSinhala} target="_blank" rel="noreferrer">
                                        <Button icon={<LinkOutlined />}>Training Materials</Button>
                                    </a>
                                </div>
                            )}
                        </div>
                    </TabPane>
                    
                    <TabPane tab="Tamil" key="2">
                        <div className="tab-content">
                            {module.videoTamil && (
                                <div className="video-container">
                                    <iframe 
                                        src={module.videoTamil} 
                                        title={`${module.title} - Tamil`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            )}
                            
                            {module.materialTamil && (
                                <div className="material-link">
                                    <a href={module.materialTamil} target="_blank" rel="noreferrer">
                                        <Button icon={<LinkOutlined />}>Training Materials</Button>
                                    </a>
                                </div>
                            )}
                        </div>
                    </TabPane>
                    
                    <TabPane tab="English" key="3">
                        <div className="tab-content">
                            {module.videoEnglish && (
                                <div className="video-container">
                                    <iframe 
                                        src={module.videoEnglish} 
                                        title={`${module.title} - English`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            )}
                            
                            {module.materialEnglish && (
                                <div className="material-link">
                                    <a href={module.materialEnglish} target="_blank" rel="noreferrer">
                                        <Button icon={<LinkOutlined />}>Training Materials</Button>
                                    </a>
                                </div>
                            )}
                        </div>
                    </TabPane>
                </Tabs>
            </Card>
        );
    };

    return (
        <div className='training'>
            {contextHolder}
            <div className='header'>
                <div className="header-title">
                    <Title level={2}>Training Modules</Title>
                </div>

                {user?.role === 'super-admin' && (
                    <Button 
                        type="primary" 
                        icon={<PlusOutlined />} 
                        onClick={() => setAddModalVisible(true)}
                    >
                        Add Training Module
                    </Button>
                )}
            </div>
            
            <Spin spinning={loading}>
                <div className='modules-grid'>
                    {trainingModules.length > 0 ? (
                        trainingModules.map(module => renderModuleCard(module))
                    ) : (
                        <div className="empty-state">
                            <Title level={4}>No training modules available</Title>
                            {user?.role === 'super-admin' && (
                                <Button 
                                    type="primary" 
                                    icon={<PlusOutlined />} 
                                    onClick={() => setAddModalVisible(true)}
                                >
                                    Add Your First Module
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </Spin>
            
            {/* Add Module Modal */}
            <Modal
                title="Add Training Module"
                open={addModalVisible}
                onCancel={() => setAddModalVisible(false)}
                footer={null}
                width={700}
            >
                <Form 
                    form={form} 
                    layout="vertical" 
                    onFinish={handleAddModule}
                >
                    <Form.Item
                        name="title"
                        label="Module Title"
                        rules={[{ required: true, message: 'Please enter module title' }]}
                    >
                        <Input placeholder="e.g., Python Basics" />
                    </Form.Item>
                    
                    <Form.Item
                        name="description"
                        label="Module Description"
                    >
                        <TextArea 
                            placeholder="Describe what this module covers" 
                            rows={3} 
                        />
                    </Form.Item>
                    
                    <Form.Item
                        name="sessionDate"
                        label="Session Date"
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Sinhala Content" key="1">
                            <Form.Item
                                name="videoSinhala"
                                label="Video URL (Sinhala)"
                            >
                                <Input placeholder="YouTube embed URL" />
                            </Form.Item>
                            
                            <Form.Item
                                name="materialSinhala"
                                label="Materials URL (Sinhala)"
                            >
                                <Input placeholder="Google Drive or other resource link" />
                            </Form.Item>
                        </TabPane>
                        
                        <TabPane tab="Tamil Content" key="2">
                            <Form.Item
                                name="videoTamil"
                                label="Video URL (Tamil)"
                            >
                                <Input placeholder="YouTube embed URL" />
                            </Form.Item>
                            
                            <Form.Item
                                name="materialTamil"
                                label="Materials URL (Tamil)"
                            >
                                <Input placeholder="Google Drive or other resource link" />
                            </Form.Item>
                        </TabPane>
                        
                        <TabPane tab="English Content" key="3">
                            <Form.Item
                                name="videoEnglish"
                                label="Video URL (English)"
                            >
                                <Input placeholder="YouTube embed URL" />
                            </Form.Item>
                            
                            <Form.Item
                                name="materialEnglish"
                                label="Materials URL (English)"
                            >
                                <Input placeholder="Google Drive or other resource link" />
                            </Form.Item>
                        </TabPane>
                    </Tabs>
                    
                    <Form.Item className="form-actions">
                        <Button onClick={() => setAddModalVisible(false)} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Add Module
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            
            {/* Edit Module Modal */}
            <Modal
                title="Edit Training Module"
                open={editModalVisible}
                onCancel={() => setEditModalVisible(false)}
                footer={null}
                width={700}
            >
                <Form 
                    form={editForm} 
                    layout="vertical" 
                    onFinish={handleEditModule}
                >
                    <Form.Item
                        name="title"
                        label="Module Title"
                        rules={[{ required: true, message: 'Please enter module title' }]}
                    >
                        <Input placeholder="e.g., Python Basics" />
                    </Form.Item>
                    
                    <Form.Item
                        name="description"
                        label="Module Description"
                    >
                        <TextArea 
                            placeholder="Describe what this module covers" 
                            rows={3} 
                        />
                    </Form.Item>
                    
                    <Form.Item
                        name="sessionDate"
                        label="Session Date"
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Sinhala Content" key="1">
                            <Form.Item
                                name="videoSinhala"
                                label="Video URL (Sinhala)"
                            >
                                <Input placeholder="YouTube embed URL" />
                            </Form.Item>
                            
                            <Form.Item
                                name="materialSinhala"
                                label="Materials URL (Sinhala)"
                            >
                                <Input placeholder="Google Drive or other resource link" />
                            </Form.Item>
                        </TabPane>
                        
                        <TabPane tab="Tamil Content" key="2">
                            <Form.Item
                                name="videoTamil"
                                label="Video URL (Tamil)"
                            >
                                <Input placeholder="YouTube embed URL" />
                            </Form.Item>
                            
                            <Form.Item
                                name="materialTamil"
                                label="Materials URL (Tamil)"
                            >
                                <Input placeholder="Google Drive or other resource link" />
                            </Form.Item>
                        </TabPane>
                        
                        <TabPane tab="English Content" key="3">
                            <Form.Item
                                name="videoEnglish"
                                label="Video URL (English)"
                            >
                                <Input placeholder="YouTube embed URL" />
                            </Form.Item>
                            
                            <Form.Item
                                name="materialEnglish"
                                label="Materials URL (English)"
                            >
                                <Input placeholder="Google Drive or other resource link" />
                            </Form.Item>
                        </TabPane>
                    </Tabs>
                    
                    <Form.Item className="form-actions">
                        <Button onClick={() => setEditModalVisible(false)} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Training
