import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Tabs, notification, Spin } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import { auth, db } from '../../firebase'
import { collection, query, where, getDocs } from 'firebase/firestore'
import Training from './Training'
import Attendance from './Attendance'
import Clubs from './Clubs'
import Volunteers from './Volunteers'
import ClubStudents from './ClubStudents'
import './styles.scss'
import NewRegistartions from './NewRegistartions'
import AllRegsitraction from './AllRegsitraction'

const Admin = () => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification()

    useEffect(() => {
        const checkAuth = async () => {
            const login = JSON.parse(localStorage.getItem('login-codeclubs'))
            if (!login) {
                navigate('/signin')
                return
            }

            try {
                const q = query(collection(db, 'users'), where('uid', '==', login.uid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const userData = userDoc.data();
                    if (userData.active === 'approved') {
                        setUser(userData);
                    } else {
                        api.error({
                            message: 'Access Denied',
                            description: 'Your account is not approved yet.'
                        });
                        auth.signOut();
                        localStorage.removeItem('login-codeclubs');
                        navigate('/signin');
                    }
                } else {
                    api.error({
                        message: 'Access Denied',
                        description: 'User document does not exist.'
                    });
                    auth.signOut();
                    localStorage.removeItem('login-codeclubs');
                    navigate('/signin');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                api.error({
                    message: 'Error',
                    description: 'An error occurred while verifying your account. Please try again.'
                });
                auth.signOut();
                localStorage.removeItem('login-codeclubs');
                navigate('/signin');
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, [navigate, api]);

    // Basic admin tabs (for regular volunteers)
    const items = [
        {
            key: '1',
            label: 'Volunteer Training',
            children: <Training user={user} />,
        },
        {
            key: '2',
            label: 'Students Attendance',
            children: <Attendance user={user} />,
        }
    ]

    // Club admin tabs (includes Volunteers and Club Students management)
    const clubAdminItems = [
        {
            key: '1',
            label: 'Volunteer Training',
            children: <Training user={user} />,
        },
        {
            key: '2',
            label: 'Students Attendance',
            children: <Attendance user={user} />,
        },
        {
            key: '6',
            label: 'Volunteers',
            children: <Volunteers user={user} />,
        },
        {
            key: '7',
            label: 'Club Students',
            children: <ClubStudents user={user} />,
        }
    ]

    // Super admin and coordinator tabs (full access)
    const superAdmin = [
        {
            key: '1',
            label: 'Volunteer Training',
            children: <Training user={user} />,
        },
        {
            key: '2',
            label: 'Students Attendance',
            children: <Attendance user={user} />,
        },
        {
            key: '3',
            label: 'Code Clubs',
            children: <Clubs user={user} />,
        },
        {
            key: '4',
            label: 'New Registrations',
            children: <NewRegistartions user={user} />,
        },
        {
            key: '5',
            label: 'All Registrations',
            children: <AllRegsitraction user={user} />,
        },
        {
            key: '6',
            label: 'Volunteers',
            children: <Volunteers user={user} />,
        },
    ]

    // Determine which tabs to show based on user role
    const getTabItems = () => {
        if (user?.role === 'super-admin' || user?.role === 'coordinator') {
            return superAdmin;
        } else if (user?.role === 'club-admin') {
            return clubAdminItems;
        } else {
            return items;
        }
    }

    if (loading) {
        return (
            <div
                className="loading-container"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spin size="large" tip="Loading..." />
            </div>
        )
    }

    return (
        <div className='admin'>
            {contextHolder}
            <div className='header'>
                <h1>Code Clubs Admin Portal</h1>
                <div className='admin-info'>
                    <p>
                        {user?.email}
                        <br />
                        <span>{user?.role}</span>
                    </p>
                    <Button
                        type='primary'
                        onClick={() => {
                            auth.signOut()
                            localStorage.removeItem('login-codeclubs')
                            navigate('/signin')
                        }}
                        size='large'
                        icon={<LogoutOutlined />}
                    >
                        Logout
                    </Button>
                </div>
            </div>

            <Tabs
                defaultActiveKey="1"
                items={getTabItems()}
            />
        </div>
    )
}

export default Admin