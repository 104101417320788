import React, { useState } from 'react'
import './styles.scss'
import { Button, Form, Input, notification, Modal, Spin } from 'antd'
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { auth, db } from '../../firebase'

const sanitizeInput = (input) => {
    return input.replace(/[^\w\s@\-.]/gi, '').trim();
};

const Signin = () => {
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [resetModalVisible, setResetModalVisible] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [resetLoading, setResetLoading] = useState(false);

    const onFinish = async (values) => {
        const email = sanitizeInput(values.email);
        const password = sanitizeInput(values.password);
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const idToken = await user.getIdToken();

            await new Promise(resolve => setTimeout(resolve, 1000));

            const q = query(collection(db, 'users'), where('uid', '==', user.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();

                if (userData.active === 'approved') {
                    api.success({
                        message: 'Login Success',
                        description: 'You have successfully logged in'
                    });
                    localStorage.setItem('login-codeclubs', JSON.stringify({
                        ...userData,
                        idToken
                    }));
                    window.location.href = '/admin';
                } else {
                    api.error({
                        message: 'Login Failed',
                        description: 'Your account is not approved yet.'
                    });
                    await auth.signOut();
                }
            } else {
                console.error('No such document!');
                api.error({
                    message: 'Login Failed',
                    description: 'User document does not exist.'
                });
                await auth.signOut();
            }
        } catch (error) {
            console.error('Error signing in:', error);
            api.error({
                message: 'Login Failed',
                description: 'Please check your email and password'
            });
        }
        setLoading(false);
    };

    const handleResetPassword = async () => {
        if (!resetEmail) {
            api.error({
                message: 'Email Required',
                description: 'Please enter your email address'
            });
            return;
        }

        const email = sanitizeInput(resetEmail);
        setResetLoading(true);
        
        try {
            await sendPasswordResetEmail(auth, email);
            api.success({
                message: 'Reset Email Sent',
                description: 'Check your email for password reset instructions'
            });
            setResetModalVisible(false);
            setResetEmail('');
        } catch (error) {
            console.error('Error sending reset email:', error);
            api.error({
                message: 'Reset Failed',
                description: 'Unable to send reset email. Please check if the email exists.'
            });
        }
        
        setResetLoading(false);
    };

    return (
        <div className='signin'>
            {contextHolder}
            <div className='container'>
                <div className='signin-header'>
                    <h1>Volunteer Login</h1>
                    <p>Login to your account for more features</p>
                </div>
                <div className='form'>
                    <Form layout='vertical' onFinish={onFinish}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary" htmlType="submit"
                                loading={loading}
                                block
                            >
                                Sign In
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className='forgot-password'>
                        <Button type="link" onClick={() => setResetModalVisible(true)}>
                            Forgot password?
                        </Button>
                    </div>
                    <div className='signup-link'>
                        <p>Don't have an account? <a href="/volunteer-signup">Sign up as a volunteer</a></p>
                    </div>
                </div>
            </div>

            <Modal
                title="Reset Password"
                open={resetModalVisible}
                onCancel={() => {
                    setResetModalVisible(false);
                    setResetEmail('');
                }}
                footer={[
                    <Button key="cancel" onClick={() => {
                        setResetModalVisible(false);
                        setResetEmail('');
                    }}>
                        Cancel
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        onClick={handleResetPassword}
                        loading={resetLoading}
                    >
                        Send Reset Link
                    </Button>,
                ]}
            >
                <div className="reset-password-form">
                    <p>Enter your email address to receive a password reset link</p>
                    <Input
                        placeholder="Email address"
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                        disabled={resetLoading}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default Signin;