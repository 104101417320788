import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

import './styles.scss'
import { Button, Divider, Form, Input, Radio, Select, Space, Spin, notification } from 'antd'

import { collection, addDoc, query, where, getDocs } from 'firebase/firestore'

import { codeclubs } from '../../Files/centers'
import { db } from '../../firebase'

const centerOptions = codeclubs.map((center) => ({
    label: center.name,
    value: center.id
}));

const sanitizeInput = (input) => {
    return input.replace(/[^\w\s\-.,']/gi, '').trim();
};

const Register = () => {
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        const activeTab = document.querySelector(".activenav");
        if (activeTab) {
            activeTab.classList.remove("activenav");
        }
        const currentTab = document.getElementById("navregister");
        if (currentTab) {
            currentTab.classList.add("activenav");
        }
    }, []);

    const onFinish = async (values) => {
        setLoading(true)

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        try {
            const sanitizedValues = {
                nearesrclub: values.nearesrclub,
                parentsName: sanitizeInput(values.parentsName),
                parentsContact: sanitizeInput(values.parentsContact),
                address: sanitizeInput(values.address),
                age: values.age,
                childName: sanitizeInput(values.childName),
                gender: values.gender,
            };

            const nameQuery = query(
                collection(db, "registrations"),
                where("childName", "==", sanitizedValues.childName)
            );
            const nameSnapshot = await getDocs(nameQuery);

            if (!nameSnapshot.empty) {
                api.error({
                    message: 'Registration Failed',
                    description: 'A registration with this child name already exists. | මෙම දරුවාගේ නමින් ලියාපදිංචියක් දැනටමත් පවතී. | இந்த குழந்தையின் பெயருடன் பதிவு ஏற்கனவே உள்ளது.'
                });
                setLoading(false);
                return;
            }

            const id = uuidv4()

            await addDoc(collection(db, "registrations"), {
                id: id,
                center: sanitizedValues.nearesrclub,
                parentsName: sanitizedValues.parentsName,
                parentsContact: sanitizedValues.parentsContact,
                address: sanitizedValues.address,
                age: sanitizedValues.age,
                childName: sanitizedValues.childName,
                gender: sanitizedValues.gender,
                attendance: [],
                isApproved: "Pending",
                date: new Date().toLocaleDateString()
            });
            api.success({
                message: 'Registration Successful',
                description: 'You have successfully registered for the CoderDojo. You will receive a confirmation SMS or email once your registration is approved. | ඔබ සාර්ථකව ලියාපදිංචි වී ඇත. ඔබගේ ලියාපදිංචිය අනුමත වූ පසු ඔබට තහවුරු කිරීමේ SMS හෝ විද්‍යුත් තැපෑලක් ලැබෙනු ඇත. | நீங்கள் வெற்றிகரமாக பதிவு செய்யப்பட்டுள்ளீர்கள். உங்கள் பதிவு அங்கீகரிக்கப்பட்டதும் உறுதிப்படுத்தல் SMS அல்லது மின்னஞ்சலைப் பெறுவீர்கள்.'
            });

            setTimeout(() => {
                window.location.reload();
            }, 3000);

        } catch (e) {
            console.error("Error adding document: ", e);
            api.error({
                message: 'Registration Failed',
                description: 'There was an error while registering. Please try again later or contact us. | ලියාපදිංචි වීමට නොහැකි විය. කරුණාකර පස්සේ නැවත උත්සාහ කරන්න හෝ අප අමතන්න. | பதிவு செய்ய முடியவில்லை. தயவுசெய்து பின்னர் மீண்டும் முயற்சிக்கவும் அல்லது எங்களை தொடர்பு கொள்ளவும். | Error adding document: ' + e

            });
        }

        setLoading(false)
    }

    return (
        <div className='regsiter'>
            {contextHolder}
            <div className='container'>
                <h1>Student Registration</h1>
                <h2>
                    You will receive a confirmation SMS or email once your registration is approved.
                    <br />
                    ඔබගේ ලියාපදිංචිය අනුමත වූ පසු ඔබට තහවුරු කිරීමේ SMS හෝ විද්‍යුත් තැපෑලක් ලැබෙනු ඇත.
                    <br />
                    உங்கள் பதிவு அங்கீகரிக்கப்பட்டதும் உறுதிப்படுத்தல் SMS அல்லது மின்னஞ்சலைப் பெறுவீர்கள்.
                </h2>

                <Divider />
                <div className='form'>
                    <Spin spinning={loading} tip='Please Wait...'>
                        <Form
                            layout='vertical'
                            onFinish={onFinish}
                            autoComplete='off'
                        >
                            <Form.Item
                                label={<p>Select your nearest Code Club<br />ඔබට ළගම පිහිටි මධ්‍යස්ථානය තෝරාගන්න | உங்களுக்கு அருகில் உள்ள மையத்தைத் தேர்ந்தெடுக்கவும்</p>}
                                name="nearesrclub"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select your nearest CoderDojo"
                                    options={centerOptions}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label={<p>Name of the Parent<br />දෙමාපියන්ගේ නම | பெற்றோரின் பெயர்</p>}
                                name="parentsName"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        max: 50,
                                    },
                                ]}
                            >
                                <Input placeholder='Enter your name' />
                            </Form.Item>

                            <Form.Item
                                label={<p>Contact Number of the Parent<br />දෙමාපියන්ගේ දුරකථන අංකය | பெற்றோரின் தொலைபேசி எண்</p>}
                                name="parentsContact"
                                rules={[
                                    {
                                        required: true,
                                        pattern: /^\d{10}$/,
                                        message: 'Please enter a valid 10-digit phone number',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter parent's contact number" />
                            </Form.Item>

                            <Form.Item
                                label={<p>Postal Address<br />තැපැල් ලිපිනය | அஞ்சல் முகவரி</p>}
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        max: 100,
                                    },
                                ]}
                            >
                                <Input placeholder="Enter postal address" />
                            </Form.Item>

                            <Form.Item
                                label={<p>Name of the Child (First and Last Name)<br />දරුවාගේ නම | குழந்தையின் பெயர்</p>}
                                name="childName"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        max: 50,
                                    },
                                ]}
                            >
                                <Input placeholder="Enter child's name" />
                            </Form.Item>

                            <Form.Item
                                label={<p>Gender of the Child<br />දරුවාගේ ස්ත්‍රී/ පුරුෂ භාවය | குழந்தையின் பாலினம்</p>}
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value='male'>Male | පිරිමි | ஆண்</Radio>
                                        <Radio value='female'>Female | ගැහැණු | பெண்</Radio>
                                        <Radio value='null'>Prefer not to say | නොකියන්න කැමති | சொல்லாமல் இருக்க விருப்பம்</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label={<p>Age of the Child (Years)<br />දරුවාගේ වයස (අවුරුදු) | குழந்தையின் வயது (ஆண்டுகள்)</p>}
                                name="age"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input type='number' placeholder="Enter child's age" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Register
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default Register