import { Button, Form, Select, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { codeclubs } from '../../../Files/centers'
import './styles.scss'
import { db } from '../../../firebase'
import { collection, getDocs, updateDoc, doc, query, where } from 'firebase/firestore'

const months = ['October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September']

const Attendance = ({ user }) => {
    const [loading, setLoading] = useState(false)
    const [students, setStudents] = useState([])
    const [filteredStudents, setFilteredStudents] = useState([])
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [searchTerm, setSearchTerm] = useState("")
    const [club, setClub] = useState("")

    useEffect(() => {
        if (user?.role === 'admin' || user?.role === 'club-admin') {
            handleGetStudents({ center: user.centers[0] })
        }
    }, [user])


    const handleGetStudents = async (values) => {
        setLoading(true);
        setClub(values.center);
        try {
            let newStudents = [];
            const q = query(
                collection(db, 'registrations'),
                where('center', '==', values.center),
                where('isApproved', '==', 'Approved')
            );

            const studentsSnapshot = await getDocs(q);

            studentsSnapshot?.forEach(doc => {
                const student = doc.data();
                student.id = doc.id;
                newStudents.push(student);
            });

            setStudents(newStudents);
            setFilteredStudents(newStudents);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleMonthChange = (value) => {
        setSelectedMonth(value)
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchTerm(value)
        const filtered = students.filter(student =>
            student.childName.toLowerCase().includes(value)
        )
        setFilteredStudents(filtered)
    }

    const handleToggleAttendance = async (studentId) => {
        if (!selectedMonth) {
            alert("Please select a month to toggle attendance.")
            return
        }

        try {
            const studentRef = doc(db, 'registrations', studentId)
            const student = students.find(student => student.id === studentId)
            let updatedAttendance

            if (student.attendance.includes(selectedMonth)) {
                updatedAttendance = student.attendance.filter(month => month !== selectedMonth)
            } else {
                updatedAttendance = [...student.attendance, selectedMonth]
            }
            await updateDoc(studentRef, {
                attendance: updatedAttendance
            })

            setStudents(prevStudents =>
                prevStudents?.map(student =>
                    student.id === studentId
                        ? { ...student, attendance: updatedAttendance }
                        : student
                )
            )

            setFilteredStudents(prevStudents =>
                prevStudents?.map(student =>
                    student.id === studentId
                        ? { ...student, attendance: updatedAttendance }
                        : student
                )
            )
        } catch (error) {
            console.log(error)
        }
    }

    const monthWithYear = (month) => {
        const monthsBeforeOctober = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'];
        const year = monthsBeforeOctober.includes(month) ? '2025' : '2024';
        return `${month} ${year}`;
    };

    return (
        <div className='attendance'>
            <div className='header'>
                <h1>Attendance</h1>
                {
                    user?.role === 'admin' &&
                    <Form
                        layout={
                            window.innerWidth < 768
                                ? 'vertical'
                                : 'inline'
                        }
                        onFinish={handleGetStudents}
                    >
                        <Form.Item
                            label='Select a center'
                            name='center'
                        >
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a center"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {user?.centers?.map((id, index) => (
                                    <Select.Option key={index} value={id}>
                                        {codeclubs.find(center => center.id === id)?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                Get Attendance
                            </Button>
                        </Form.Item>
                    </Form>
                }

                {
                    user?.role === 'club-admin' &&
                    <Form
                        layout={
                            window.innerWidth < 768
                                ? 'vertical'
                                : 'inline'
                        }
                        onFinish={handleGetStudents}
                        initialValues={{ center: user?.centers?.[0] }}
                    >
                        <Form.Item
                            label='Select a center'
                            name='center'
                        >
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a center"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {user?.centers?.map((id, index) => (
                                    <Select.Option key={index} value={id}>
                                        {codeclubs.find(center => center.id === id)?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                Get Attendance
                            </Button>
                        </Form.Item>
                    </Form>
                }

                {(user?.role === 'super-admin' || user?.role === 'coordinator') &&
                    <Form
                        layout={
                            window.innerWidth < 768
                                ? 'vertical'
                                : 'inline'
                        }
                        onFinish={handleGetStudents}
                    >
                        <Form.Item
                            label='Select a center'
                            name='center'
                        >
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a center"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {codeclubs?.map((center, index) => (
                                    <Select.Option key={index} value={center.id}>
                                        {center.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                Get Attendance
                            </Button>
                        </Form.Item>
                    </Form>}
            </div>
            <div className='search'>
                <Input
                    placeholder="Search student by name"
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ width: 300 }}
                />

                <Select
                    style={{ width: 300 }}
                    placeholder="Select a month to toggle attendance"
                    onChange={handleMonthChange}
                >
                    {months?.map((month, index) => (
                        <Select.Option key={index} value={month}>
                            {monthWithYear(month)}
                        </Select.Option>
                    ))}
                </Select>
            </div>

            {
                club !== "" && <h3>Showing attendance for: {codeclubs.find(center => center.id === club)?.name}</h3>
            }
            {
                club !== "" && <h3>Total Approved Students: {filteredStudents.length}</h3>
            }

            <div className='students'>
                {filteredStudents?.map((student, index) => (
                    <div key={index} className='student'>
                        <div className='info'>
                            <h2>{student.childName}</h2>
                            <p><strong>Center: </strong>{
                                codeclubs.find(center => center.id === student.center)?.name
                            }</p>
                        </div>
                        <div className='data'>
                            <div className='boxes'>
                                {months?.map((month, idx) => (
                                    <div
                                        key={idx}
                                        className={`box ${student.attendance.includes(month) ? 'active' : ''}`}
                                    >
                                        <div className='attendatnt'>
                                            {student.attendance.includes(month) ? '✅' : '❌'}
                                        </div>
                                        {month}
                                    </div>
                                ))}
                            </div>
                            {(user?.role === 'super-admin' || user?.role === 'admin' || user?.role === 'club-admin') &&
                                <Button
                                    type='primary'
                                    onClick={() => handleToggleAttendance(student.id)}
                                    disabled={!selectedMonth}
                                >
                                    {
                                        !selectedMonth
                                            ? 'Select a month to toggle attendance'
                                            : student.attendance.includes(selectedMonth)
                                                ? `Mark as absent for ${selectedMonth}`
                                                : `Mark as present for ${selectedMonth}`
                                    }
                                </Button>
                            }
                        </div>
                        <p><strong>
                            Total Attendance: {student.attendance.length} / {months.length}
                        </strong>
                        </p>
                    </div>
                ))}
                {
                    loading && <p>Loading...</p>
                }
                {
                    club === "" && <p>Please select a center to view attendance</p>
                }
                {
                    filteredStudents.length === 0 && !loading && club !== "" && <p>No students found</p>
                }
            </div>
        </div>
    )
}

export default Attendance
