import { Button, Drawer, Form, Input, Select, notification, Table, Spin, Tag, Modal, Space, Popconfirm, Row, Col } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { codeclubs } from '../../../Files/centers'
import { auth, db } from '../../../firebase'
import { createUserWithEmailAndPassword, deleteUser, getAuth } from 'firebase/auth'
import { collection, addDoc, getDocs, updateDoc, doc, setDoc, deleteDoc } from 'firebase/firestore'
import loginsData from '../../../Files/logins'
import './styles.scss'

const Volunteers = ({ user }) => {
    const [addVolunteerOpen, setAddVolunteerOpen] = useState(false)
    const [editVolunteerOpen, setEditVolunteerOpen] = useState(false)
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [selectedRole, setSelectedRole] = useState('admin');
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const centerOptions = codeclubs.map(center => ({
        label: center.name,
        value: center.id
    }));

    const fetchUsers = async () => {
        setLoadingUsers(true);
        try {
            const querySnapshot = await getDocs(collection(db, 'users'));
            let usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            // If user is club-admin, filter to show only users from their centers
            if (user?.role === 'club-admin' && user?.centers?.length > 0) {
                usersData = usersData.filter(userData => {
                    // Only include users that have at least one center in common with the club-admin
                    if (!userData.centers || userData.centers.length === 0) return false;
                    
                    return userData.centers.some(center => user.centers.includes(center));
                });
            }
            
            // Sort users to show pending users at the top
            usersData.sort((a, b) => {
                // First sort by active status (pending first)
                if (a.active === 'pending' && b.active !== 'pending') return -1;
                if (a.active !== 'pending' && b.active === 'pending') return 1;
                
                // Then sort by email if status is the same
                return a.email.localeCompare(b.email);
            });
            
            setUsers(usersData);
        } catch (error) {
            console.error('Error fetching users:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setLoadingUsers(false);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const onFinish = async (values) => {
        const { email, password, role, centers } = values;
        setIsLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                email: email,
                role: role,
                centers: centers || [],
                active: 'pending',
            });

            api.success({
                message: 'User Created',
                description: `The user ${email} has been successfully created.`,
            });

            setAddVolunteerOpen(false);
            form.resetFields();

            // Refresh the users list
            fetchUsers();

        } catch (error) {
            console.error('Error creating user:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setIsLoading(false);
    };

    const handleActivate = async () => {
        if (selectedUser) {
            try {
                await updateDoc(doc(db, 'users', selectedUser.id), {
                    active: 'approved'
                });
                api.success({
                    message: 'User Activated',
                    description: `The user ${selectedUser.email} has been successfully activated.`,
                });
                fetchUsers();
            } catch (error) {
                console.error('Error activating user:', error);
                api.error({
                    message: 'Error',
                    description: error.message,
                });
            }
        }
        setConfirmModalVisible(false);
        setSelectedUser(null);
    };

    const showEditDrawer = (record) => {
        setSelectedUser(record);
        editForm.setFieldsValue({
            email: record.email,
            role: record.role,
            centers: record.centers || [],
            active: record.active || 'pending'
        });
        setEditVolunteerOpen(true);
    };

    const handleEdit = async (values) => {
        setIsLoading(true);
        try {
            await updateDoc(doc(db, 'users', selectedUser.id), {
                role: values.role,
                centers: values.centers || [],
                active: values.active
            });

            api.success({
                message: 'User Updated',
                description: `The user ${selectedUser.email} has been successfully updated.`,
            });

            setEditVolunteerOpen(false);
            editForm.resetFields();
            setSelectedUser(null);
            fetchUsers();
        } catch (error) {
            console.error('Error updating user:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setIsLoading(false);
    };

    const handleDelete = async (record) => {
        setIsLoading(true);
        try {
            // Delete from Firestore
            await deleteDoc(doc(db, 'users', record.id));
            
            api.success({
                message: 'User Deleted',
                description: `The user ${record.email} has been successfully deleted from the database. Note: The user's authentication account will need to be deleted manually by an administrator.`,
            });
            
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
            api.error({
                message: 'Error',
                description: `Error deleting user: ${error.message}`,
            });
        }
        setIsLoading(false);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <span style={{ fontWeight: searchedColumn === dataIndex ? 'bold' : 'normal' }}>
                    {text}
                </span>
            ) : (
                text
            ),
    });

    // Check if user has permission to add volunteers
    const canAddVolunteer = user?.role === 'super-admin';
    
    // Check if user can edit volunteers (only super-admin)
    const canEditVolunteer = user?.role === 'super-admin';
    
    // Check if user can delete volunteers (super-admin or club-admin)
    const canDeleteVolunteer = user?.role === 'super-admin' || user?.role === 'club-admin';
    
    // Check if user can approve volunteers (super-admin or club-admin)
    const canApproveVolunteer = user?.role === 'super-admin' || user?.role === 'club-admin';

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 200,
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            filters: [
                { text: 'Super Admin', value: 'super-admin' },
                { text: 'Volunteer', value: 'admin' },
                { text: 'Coordinator', value: 'coordinator' },
                { text: 'Club Admin', value: 'club-admin' },
            ],
            onFilter: (value, record) => record.role === value,
            render: (role) => {
                let color = '';
                let displayText = role.toUpperCase();
                
                if (role === 'super-admin') {
                    color = 'volcano';
                } else if (role === 'admin') {
                    color = 'geekblue';
                    displayText = 'VOLUNTEER';
                } else if (role === 'coordinator') {
                    color = 'green';
                } else if (role === 'club-admin') {
                    color = 'purple';
                    displayText = 'CLUB ADMIN';
                }
                
                return <Tag color={color} key={role}>{displayText}</Tag>;
            },
            width: 150,
        },
        {
            title: 'Clubs',
            dataIndex: 'centers',
            key: 'centers',
            filters: codeclubs.map(center => ({
                text: center.name,
                value: center.id,
            })),
            onFilter: (value, record) => record.centers && record.centers.includes(value),
            render: (centers) => (
                centers ? centers.map(centerId => {
                    const center = codeclubs.find(c => c.id === centerId);
                    return center ? <Tag key={centerId}>{center.name}</Tag> : <Tag key={centerId}>{centerId}</Tag>;
                }) : ''
            ),
            width: 300,
        },
        {
            title: 'Active Status',
            dataIndex: 'active',
            key: 'active',
            filters: [
                { text: 'Active', value: 'approved' },
                { text: 'Pending', value: 'pending' },
            ],
            onFilter: (value, record) => record.active === value,
            render: (text) => (
                text === 'approved' ? (
                    <Tag color="green">Active</Tag>
                ) : (
                    <Tag color="red">Pending</Tag>
                )
            ),
            width: 100,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => {
                if (!canDeleteVolunteer && !canEditVolunteer && !canApproveVolunteer) {
                    return null;
                }
                
                return (
                    <Space>
                        {record.active === 'pending' && canApproveVolunteer && (
                            <Button 
                                type="primary" 
                                size="small"
                                onClick={() => {
                                    setSelectedUser(record);
                                    setConfirmModalVisible(true);
                                }}
                            >
                                Activate
                            </Button>
                        )}
                        {canEditVolunteer && (
                            <Button 
                                icon={<EditOutlined />} 
                                onClick={() => showEditDrawer(record)}
                                size="small"
                            />
                        )}
                        {canDeleteVolunteer && (
                            <Popconfirm
                                title="Delete this user?"
                                description="This action cannot be undone."
                                onConfirm={() => handleDelete(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button 
                                    danger 
                                    icon={<DeleteOutlined />}
                                    size="small"
                                />
                            </Popconfirm>
                        )}
                    </Space>
                );
            },
            width: 150,
        },
    ];

    const createAllVolunteers = async () => {
        setIsLoading(true);
        try {
            for (const login of loginsData) {
                const { email, password, role, centerId } = login;
                try {
                    console.log(`Creating ${email}...`);
                    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                    const user = userCredential.user;

                    await setDoc(doc(db, 'users', user.uid), {
                        uid: user.uid,
                        email: email,
                        role: role,
                        centers: centerId || [],
                        active: 'approved',
                    });

                    console.log(`Successfully created ${email}`);
                } catch (error) {
                    console.error(`Error creating ${email}:`, error);
                    console.log(`Skipped ${email}`);
                }
            }

            api.success({
                message: 'All Users Created',
                description: 'All volunteers have been successfully created.',
            });
            fetchUsers();

        } catch (error) {
            console.error('Error creating users:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setIsLoading(false);
    };

    return (
        <div className='volunteers'>
            {contextHolder}
            <div className='header'>
                <Row gutter={[16, 16]} align="middle" style={{ width: '100%', marginBottom: 16 }}>
                    <Col flex="auto">
                        <h2 style={{ margin: 0 }}>Volunteers Management</h2>
                    </Col>
                    {canAddVolunteer && (
                        <Col>
                            <Button 
                                type='primary' 
                                onClick={() => setAddVolunteerOpen(true)}
                            >
                                Add Volunteer
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>

            <Spin spinning={loadingUsers}>
                <Table 
                    dataSource={users} 
                    columns={columns} 
                    rowKey='id'
                    pagination={{ pageSize: 10 }}
                />
            </Spin>

            {/* Add Volunteer Drawer */}
            <Drawer
                title="Add User"
                width={400}
                onClose={() => setAddVolunteerOpen(false)}
                open={addVolunteerOpen}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                    initialValues={{ role: 'admin' }}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { required: true, message: 'Please enter the email' },
                            { type: 'email', message: 'Please enter a valid email' }
                        ]}
                    >
                        <Input placeholder="Enter email" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            { required: true, message: 'Please enter the password' },
                            { min: 6, message: 'Password must be at least 6 characters' }
                        ]}
                    >
                        <Input.Password placeholder="Enter password" />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[{ required: true, message: 'Please select a role' }]}
                    >
                        <Select
                            placeholder="Select role"
                            onChange={(value) => {
                                setSelectedRole(value);
                                if (value === 'super-admin' || value === 'coordinator') {
                                    form.setFieldsValue({ centers: [] });
                                }
                            }}
                        >
                            <Select.Option value="super-admin">Super Admin</Select.Option>
                            <Select.Option value="admin">Volunteer</Select.Option>
                            <Select.Option value="coordinator">Coordinator</Select.Option>
                            <Select.Option value="club-admin">Club Admin</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="centers"
                        label="Centers"
                        rules={[
                            {
                                required: selectedRole === 'admin' || selectedRole === 'club-admin',
                                message: 'Please select at least one center'
                            }
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select centers"
                            options={centerOptions}
                            disabled={selectedRole === 'super-admin' || selectedRole === 'coordinator'}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Add User
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>

            {/* Edit Volunteer Drawer */}
            <Drawer
                title="Edit User"
                width={400}
                onClose={() => {
                    setEditVolunteerOpen(false);
                    setSelectedUser(null);
                }}
                open={editVolunteerOpen}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    layout="vertical"
                    onFinish={handleEdit}
                    form={editForm}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[{ required: true, message: 'Please select a role' }]}
                    >
                        <Select
                            placeholder="Select role"
                            onChange={(value) => {
                                if (value === 'super-admin' || value === 'coordinator') {
                                    editForm.setFieldsValue({ centers: [] });
                                }
                            }}
                        >
                            <Select.Option value="super-admin">Super Admin</Select.Option>
                            <Select.Option value="admin">Volunteer</Select.Option>
                            <Select.Option value="coordinator">Coordinator</Select.Option>
                            <Select.Option value="club-admin">Club Admin</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="centers"
                        label="Centers"
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select centers"
                            options={centerOptions}
                            disabled={editForm.getFieldValue('role') === 'super-admin' || editForm.getFieldValue('role') === 'coordinator'}
                        />
                    </Form.Item>

                    <Form.Item
                        name="active"
                        label="Status"
                        rules={[{ required: true, message: 'Please select a status' }]}
                    >
                        <Select placeholder="Select status">
                            <Select.Option value="approved">Active</Select.Option>
                            <Select.Option value="pending">Pending</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Update User
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>

            {/* Activation Confirmation Modal */}
            <Modal
                title="Confirm Activation"
                open={confirmModalVisible}
                onOk={handleActivate}
                onCancel={() => {
                    setConfirmModalVisible(false);
                    setSelectedUser(null);
                }}
            >
                <p>Are you sure you want to activate this user?</p>
            </Modal>
        </div>
    )
}

export default Volunteers