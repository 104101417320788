import { Button, Form, Input, Select, notification, Table, Spin, Tag, Modal, Space, Popconfirm, Row, Col, Drawer, Card } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { SearchOutlined, EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, UserOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons'

import { codeclubs } from '../../../Files/centers'
import { db } from '../../../firebase'
import { collection, getDocs, updateDoc, doc, deleteDoc, query, where } from 'firebase/firestore'
import './styles.scss'

const ClubStudents = ({ user }) => {
    const [editStudentOpen, setEditStudentOpen] = useState(false)
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [students, setStudents] = useState([]);
    const [loadingStudents, setLoadingStudents] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedClub, setSelectedClub] = useState(null);
    const [clubSummary, setClubSummary] = useState({});
    const searchInput = useRef(null);

    const clubOptions = user?.centers?.map(centerId => {
        const center = codeclubs.find(c => c.id === centerId);
        return center ? { label: center.name, value: center.id } : null;
    }).filter(Boolean) || [];

    const fetchStudents = async () => {
        setLoadingStudents(true);
        try {
            const registrationsRef = collection(db, 'registrations');
            const snapshot = await getDocs(registrationsRef);
            let registrationsData = snapshot.docs.map((doc) => ({
                docId: doc.id,
                ...doc.data(),
            }));
            
            // If club-admin, filter by their centers
            if (user?.role === 'club-admin' && user?.centers?.length > 0) {
                registrationsData = registrationsData.filter(reg => 
                    user.centers.includes(reg.center)
                );
            }
            
            // Filter by selected club if any
            if (selectedClub) {
                registrationsData = registrationsData.filter(reg => reg.center === selectedClub);
            }
            
            // Calculate club summary
            const summary = {};
            registrationsData.forEach(reg => {
                const center = reg.center;
                if (!summary[center]) {
                    summary[center] = {
                        total: 0,
                        pending: 0,
                        approved: 0,
                        rejected: 0
                    };
                }
                
                summary[center].total++;
                
                const status = reg.isApproved || reg.status;
                if (status === 'Pending') summary[center].pending++;
                if (status === 'Approved') summary[center].approved++;
                if (status === 'Rejected') summary[center].rejected++;
            });
            
            setClubSummary(summary);
            
            // Identify duplicates by childName and parentsContact
            const counts = {};
            registrationsData.forEach((reg) => {
                const key = `${reg.childName}-${reg.parentsContact}`;
                counts[key] = counts[key] ? counts[key] + 1 : 1;
                reg.duplicateKey = key; // Store the key for grouping
            });

            // Group registrations by duplicateKey
            const groupMap = {};
            registrationsData.forEach((reg) => {
                if (groupMap[reg.duplicateKey]) {
                    groupMap[reg.duplicateKey].push(reg);
                } else {
                    groupMap[reg.duplicateKey] = [reg];
                }
            });

            // Prepare data for the table
            const groupedData = [];
            Object.values(groupMap).forEach((group) => {
                if (group.length > 1) {
                    // Duplicates
                    groupedData.push({
                        key: group[0].duplicateKey,
                        isDuplicate: true,
                        ...group[0],
                        // Mark each child as a duplicate
                        children: group.map((reg) => ({ ...reg, key: reg.docId, isDuplicate: true })),
                    });
                } else {
                    // Unique records
                    groupedData.push({
                        key: group[0].docId,
                        isDuplicate: false,
                        ...group[0],
                    });
                }
            });

            // Sort to show pending students at the top and duplicates first
            groupedData.sort((a, b) => {
                // First, prioritize duplicates
                if (a.isDuplicate && !b.isDuplicate) return -1;
                if (!a.isDuplicate && b.isDuplicate) return 1;

                // Then, prioritize pending status
                const aStatus = a.isApproved || a.status;
                const bStatus = b.isApproved || b.status;
                if (aStatus === 'Pending' && bStatus !== 'Pending') return -1;
                if (aStatus !== 'Pending' && bStatus === 'Pending') return 1;

                // If both have same status, sort by name
                return a.childName.localeCompare(b.childName);
            });

            setStudents(groupedData);
        } catch (error) {
            console.error('Error fetching students:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setLoadingStudents(false);
    };

    useEffect(() => {
        fetchStudents();
    }, [selectedClub]);

    const handleApprove = async () => {
        if (selectedStudent) {
            try {
                await updateDoc(doc(db, 'registrations', selectedStudent.docId), {
                    isApproved: 'approved',
                    status: 'approved'
                });
                api.success({
                    message: 'Student Approved',
                    description: `The student ${selectedStudent.childName} has been successfully approved.`,
                });
                fetchStudents();
            } catch (error) {
                console.error('Error approving student:', error);
                api.error({
                    message: 'Error',
                    description: error.message,
                });
            }
        }
        setConfirmModalVisible(false);
        setSelectedStudent(null);
    };

    const handleReject = async () => {
        if (selectedStudent) {
            try {
                await updateDoc(doc(db, 'registrations', selectedStudent.docId), {
                    isApproved: 'rejected',
                    status: 'rejected'
                });
                api.success({
                    message: 'Student Rejected',
                    description: `The student ${selectedStudent.childName} has been rejected.`,
                });
                fetchStudents();
            } catch (error) {
                console.error('Error rejecting student:', error);
                api.error({
                    message: 'Error',
                    description: error.message,
                });
            }
        }
        setConfirmModalVisible(false);
        setSelectedStudent(null);
    };

    const showEditDrawer = (record) => {
        setSelectedStudent(record);
        editForm.setFieldsValue({
            childName: record.childName,
            age: record.age,
            gender: record.gender,
            parentsName: record.parentsName,
            parentsContact: record.parentsContact,
            parentsEmail: record.parentsEmail,
            address: record.address
        });
        setEditStudentOpen(true);
    };

    const handleEdit = async (values) => {
        setIsLoading(true);
        try {
            await updateDoc(doc(db, 'registrations', selectedStudent.id), values);

            api.success({
                message: 'Student Updated',
                description: `The student ${selectedStudent.name} has been successfully updated.`,
            });

            setEditStudentOpen(false);
            editForm.resetFields();
            setSelectedStudent(null);
            fetchStudents();
        } catch (error) {
            console.error('Error updating student:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setIsLoading(false);
    };

    const handleDelete = async (record) => {
        setIsLoading(true);
        try {
            await deleteDoc(doc(db, 'registrations', record.docId));
            
            api.success({
                message: 'Student Deleted',
                description: `The student ${record.childName} has been successfully deleted.`,
            });
            
            fetchStudents();
        } catch (error) {
            console.error('Error deleting student:', error);
            api.error({
                message: 'Error',
                description: `Error deleting student: ${error.message}`,
            });
        }
        setIsLoading(false);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <span style={{ fontWeight: searchedColumn === dataIndex ? 'bold' : 'normal' }}>
                    {text}
                </span>
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'childName',
            key: 'childName',
            width: 150,
            render: (text, record) => {
                const style = record.isDuplicate ? { backgroundColor: '#fffb8f' } : {};
                return <div style={style}>{text}</div>;
            },
            ...getColumnSearchProps('childName'),
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            width: 80,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
        },
        {
            title: 'Parent Name',
            dataIndex: 'parentsName',
            key: 'parentsName',
            width: 150,
            ...getColumnSearchProps('parentsName'),
        },
        {
            title: 'Parent Phone',
            dataIndex: 'parentsContact',
            key: 'parentsContact',
            width: 150,
            ...getColumnSearchProps('parentsContact'),
        },
        {
            title: 'Club',
            dataIndex: 'center',
            key: 'center',
            width: 150,
            render: (centerId) => {
                const center = codeclubs.find(c => c.id === centerId);
                return center ? center.name : centerId;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (status, record) => {
                const finalStatus = record.isApproved || status;
                return finalStatus === 'Approved' ? (
                    <Tag color="green">Approved</Tag>
                ) : finalStatus === 'Rejected' ? (
                    <Tag color="red">Rejected</Tag>
                ) : (
                    <Tag color="orange">Pending</Tag>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => {
                // Skip rendering actions for children in duplicate groups
                if (record.isDuplicate && record.key !== record.duplicateKey) return null;

                return (
                    <Space>
                        {(record.status === 'Pending' || record.isApproved === 'Pending') && (
                            <>
                                <Button 
                                    type="primary" 
                                    size="small"
                                    icon={<CheckOutlined />}
                                    onClick={() => {
                                        setSelectedStudent(record);
                                        setConfirmModalVisible(true);
                                    }}
                                />
                                <Button 
                                    danger
                                    size="small"
                                    icon={<CloseOutlined />}
                                    onClick={() => {
                                        setSelectedStudent(record);
                                        setConfirmModalVisible(true);
                                    }}
                                />
                            </>
                        )}
                        <Button 
                            icon={<EditOutlined />} 
                            onClick={() => showEditDrawer(record)}
                            size="small"
                        />
                        <Popconfirm
                            title="Delete this student?"
                            description="This action cannot be undone."
                            onConfirm={() => handleDelete(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button 
                                danger 
                                icon={<DeleteOutlined />}
                                size="small"
                            />
                        </Popconfirm>
                    </Space>
                );
            },
            width: 150,
        },
    ];

    return (
        <div className='club-students'>
            {contextHolder}
            <div className='header'>
                <Row gutter={[16, 16]} align="middle" style={{ width: '100%', marginBottom: 16 }}>
                    <Col flex="auto">
                        <h2 style={{ margin: 0 }}>Club Students Management</h2>
                    </Col>
                    <Col>
                        <Select
                            placeholder="Filter by Club"
                            style={{ width: 200 }}
                            allowClear
                            value={selectedClub}
                            onChange={setSelectedClub}
                            options={clubOptions}
                        />
                    </Col>
                </Row>
            </div>

            {/* Club Summary Cards */}
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                {Object.entries(clubSummary).map(([clubId, summary]) => {
                    const center = codeclubs.find(c => c.id === clubId);
                    const clubName = center ? center.name : clubId;

                    return (
                        <Col key={clubId} xs={24} sm={12} md={8} lg={6}>
                            <Card 
                                title={clubName} 
                                extra={<UserOutlined />}
                                style={{ 
                                    textAlign: 'center', 
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)' 
                                }}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <div style={{ color: '#1890ff', fontSize: '20px', fontWeight: 'bold' }}>
                                            {summary.total}
                                        </div>
                                        <div>Total</div>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{ color: 'green', fontSize: '20px', fontWeight: 'bold' }}>
                                            {summary.approved}
                                        </div>
                                        <div>Approved</div>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{ color: 'orange', fontSize: '20px', fontWeight: 'bold' }}>
                                            {summary.pending}
                                        </div>
                                        <div>Pending</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    );
                })}
            </Row>

            <Spin spinning={loadingStudents}>
                <Table 
                    dataSource={students} 
                    columns={columns} 
                    rowKey='docId'
                    pagination={{ pageSize: 10 }}
                    rowClassName={record => record.status === 'pending' ? 'pending-row' : ''}
                />
            </Spin>

            <Drawer
                title="Edit Student"
                width={400}
                onClose={() => {
                    setEditStudentOpen(false);
                    setSelectedStudent(null);
                }}
                open={editStudentOpen}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    layout="vertical"
                    onFinish={handleEdit}
                    form={editForm}
                >
                    <Form.Item
                        name="childName"
                        label="Student Name"
                        rules={[{ required: true, message: 'Please enter student name' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="age"
                        label="Age"
                        rules={[{ required: true, message: 'Please enter age' }]}
                    >
                        <Input type="number" />
                    </Form.Item>

                    <Form.Item
                        name="gender"
                        label="Gender"
                        rules={[{ required: true, message: 'Please enter gender' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="parentsName"
                        label="Parent Name"
                        rules={[{ required: true, message: 'Please enter parent name' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="parentsContact"
                        label="Parent Phone"
                        rules={[{ required: true, message: 'Please enter parent phone' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="parentsEmail"
                        label="Parent Email"
                        rules={[
                            { required: true, message: 'Please enter parent email' },
                            { type: 'email', message: 'Please enter a valid email' }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label="Address"
                        rules={[{ required: true, message: 'Please enter address' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Update Student
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>

            {/* Confirmation Modal */}
            <Modal
                title="Confirm Action"
                open={confirmModalVisible}
                onOk={selectedStudent?.status === 'pending' ? handleApprove : handleReject}
                onCancel={() => {
                    setConfirmModalVisible(false);
                    setSelectedStudent(null);
                }}
            >
                <p>Are you sure you want to {selectedStudent?.status === 'pending' ? 'approve' : 'reject'} this student?</p>
            </Modal>
        </div>
    )
}

export default ClubStudents 