import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Popconfirm, notification, Input, Tag, Space, Row, Col, Card } from 'antd';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { codeclubs } from '../../../Files/centers';
import { 
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, 
    PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const AllRegistrations = () => {
    const [registrations, setRegistrations] = useState([]);
    const [filteredRegistrations, setFilteredRegistrations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dashboardStats, setDashboardStats] = useState({
        byCenter: [],
        byAge: [],
        byGender: []
    });
    const searchInput = useRef(null);
    const [api, contextHolder] = notification.useNotification();

    const centerMap = codeclubs.reduce((map, center) => {
        map[center.id] = center.name;
        return map;
    }, {});

    useEffect(() => {
        fetchRegistrations();
    }, []);

    const calculateDashboardStats = (registrationsData) => {
        // By Center
        const centerCounts = registrationsData.reduce((acc, reg) => {
            const centerName = centerMap[reg.center] || reg.center;
            acc[centerName] = (acc[centerName] || 0) + 1;
            return acc;
        }, {});
        const byCenter = Object.entries(centerCounts).map(([name, count]) => ({ name, count }));

        // By Age
        const ageBuckets = {
            '0-5': 0,
            '6-10': 0,
            '11-15': 0,
            '16+': 0
        };
        registrationsData.forEach(reg => {
            const age = parseInt(reg.age);
            if (age <= 5) ageBuckets['0-5']++;
            else if (age <= 10) ageBuckets['6-10']++;
            else if (age <= 15) ageBuckets['11-15']++;
            else ageBuckets['16+']++;
        });
        const byAge = Object.entries(ageBuckets).map(([name, count]) => ({ name, count }));

        // By Gender
        const genderCounts = registrationsData.reduce((acc, reg) => {
            const gender = reg.gender.toLowerCase();
            if (gender === 'male' || gender === 'female') {
                acc[gender] = (acc[gender] || 0) + 1;
            }
            return acc;
        }, {});
        const byGender = Object.entries(genderCounts).map(([name, count]) => ({ 
            name: name.charAt(0).toUpperCase() + name.slice(1), 
            count 
        }));

        setDashboardStats({ byCenter, byAge, byGender });
    };

    const exportToExcel = () => {
        const dataToExport = registrations
            .flatMap((item) => (item.children ? item.children : item))
            .map((item) => ({
                'Child Name': item.childName,
                'Parent Name': item.parentsName,
                'Parent Contact': item.parentsContact,
                Center: centerMap[item.center] || item.center,
                Age: item.age,
                Gender: item.gender,
                Date: item.date,
            }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Registrations');

        const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'registrations.xlsx');
    };

    const fetchRegistrations = async () => {
        setLoading(true);
        try {
            const registrationsRef = collection(db, 'registrations');
            const snapshot = await getDocs(registrationsRef);
            const registrationsData = snapshot.docs.map((doc) => ({
                docId: doc.id,
                ...doc.data(),
            }));

            // Calculate dashboard stats
            calculateDashboardStats(registrationsData);

            // Existing duplicate detection logic
            const counts = {};
            registrationsData.forEach((reg) => {
                const key = `${reg.childName}-${reg.parentsContact}`;
                counts[key] = counts[key] ? counts[key] + 1 : 1;
                reg.duplicateKey = key;
            });

            const groupMap = {};
            registrationsData.forEach((reg) => {
                if (groupMap[reg.duplicateKey]) {
                    groupMap[reg.duplicateKey].push(reg);
                } else {
                    groupMap[reg.duplicateKey] = [reg];
                }
            });

            const groupedData = [];
            Object.values(groupMap).forEach((group) => {
                if (group.length > 1) {
                    groupedData.push({
                        key: group[0].duplicateKey,
                        isDuplicate: true,
                        ...group[0],
                        children: group.map((reg) => ({ ...reg, key: reg.docId, isDuplicate: true })),
                    });
                } else {
                    groupedData.push({
                        key: group[0].docId,
                        isDuplicate: false,
                        ...group[0],
                    });
                }
            });

            groupedData.sort((a, b) => b.isDuplicate - a.isDuplicate);

            setRegistrations(groupedData);
            setFilteredRegistrations(groupedData);
        } catch (error) {
            console.error('Error fetching registrations:', error);
        }
        setLoading(false);
    };

    const handleDelete = async (docId) => {
        try {
            await deleteDoc(doc(db, 'registrations', docId));
            api.success({
                message: 'Delete Successful',
                description: 'The registration has been deleted successfully.',
            });
            fetchRegistrations();
        } catch (error) {
            console.error('Error deleting registration:', error);
            api.error({
                message: 'Delete Failed',
                description: 'There was an error deleting the registration.',
            });
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            const searchValue = value.toLowerCase();
            if (dataIndex === 'childName') {
                return record.childName.toLowerCase().includes(searchValue);
            }
            if (dataIndex === 'parentsName') {
                return record.parentsName.toLowerCase().includes(searchValue);
            }
            if (dataIndex === 'parentsContact') {
                return record.parentsContact.toLowerCase().includes(searchValue);
            }
            return false;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => {
                    searchInput.current?.select();
                }, 100);
            }
        },
        render: (text) => text,
    });

    const columns = [
        {
            title: 'Child Name',
            dataIndex: 'childName',
            key: 'childName',
            width: '12.5%',
            ...getColumnSearchProps('childName'),
            render: (text, record) => {
                const style = record.isDuplicate ? { backgroundColor: '#fffb8f' } : {};
                return <div style={style}>{text}</div>;
            },
        },
        {
            title: 'Parent Name',
            dataIndex: 'parentsName',
            key: 'parentsName',
            width: '12.5%',
            ...getColumnSearchProps('parentsName'),
        },
        {
            title: 'Parent Contact',
            dataIndex: 'parentsContact',
            key: 'parentsContact',
            width: '12.5%',
            ...getColumnSearchProps('parentsContact'),
        },
        {
            title: 'Center',
            dataIndex: 'center',
            key: 'center',
            width: '12.5%',
            render: (centerId) => centerMap[centerId] || centerId,
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            width: '12.5%',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: '12.5%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '12.5%',
        },
        {
            title: 'Action',
            key: 'action',
            width: '12.5%',
            render: (_, record) => {
                if (record.isDuplicate && !record.children) {
                    return (
                        <Popconfirm
                            title="Are you sure you want to delete this registration?"
                            onConfirm={() => handleDelete(record.docId)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger type="primary">
                                Delete Duplicates
                            </Button>
                        </Popconfirm>
                    );
                } else {
                    return (
                        <Popconfirm
                            title="Are you sure you want to delete this registration?"
                            onConfirm={() => handleDelete(record.docId)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    )
                }
            },
        },
    ];

    return (
        <div className="all-registrations">
            {contextHolder}
            <h1>All Registrations</h1>
            
            {/* Dashboard Section */}
            <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
                {/* By Center */}
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Card title="Registrations by Center" bordered={false}>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={dashboardStats.byCenter}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="count" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>

                {/* By Age */}
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Card title="Registrations by Age Group" bordered={false}>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={dashboardStats.byAge}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="count"
                                >
                                    {dashboardStats.byAge.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>

                {/* By Gender */}
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Card title="Registrations by Gender" bordered={false}>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={dashboardStats.byGender}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="count"
                                >
                                    {dashboardStats.byGender.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>

            <div style={{ display: 'flex', marginBottom: 16 }}>
                <Button type="primary" onClick={exportToExcel} style={{ marginRight: 16 }}>
                    Export All to Excel
                </Button>
            </div>

            <Table 
                dataSource={registrations} 
                columns={columns} 
                rowKey='key'
                expandable={{
                    expandedRowRender: record => record.children ? (
                        <Table 
                            columns={columns.slice(0, -1)} 
                            dataSource={record.children} 
                            pagination={false} 
                            rowKey='key'
                        />
                    ) : null,
                    rowExpandable: record => record.isDuplicate,
                }}
            />
        </div>
    );
};

export default AllRegistrations;